<template>
  <div class="year-picker-background" v-click-outside="showRangeWindow">
    <div class="label-year">
      {{ label }}
    </div>
    <input
      @focus.stop="checkValue"
      v-mask="yearRangeMask"
      @input="setValueYearRange"
      type="text"
      :placeholder="placeholder"
      v-model="yearRangeValue"
      :class="{ 'invalid-input': invalid }"
      ref="month"
    />
    <small class="invalid-label" v-if="invalid">{{ userText }}</small>
    <div id="month-picker" v-show="showRangeYear">
      <div class="month_picker_container">
        <div class="year_select_area">
          <img
            src="~@/assets/img/icons/arrowNextYear.svg"
            class="right_arrow arrow"
            @click="clickPage(0)"
          />
          <div class="now_year">{{ nowPage }}</div>
          <img src="~@/assets/img/icons/arrowNextYear.svg" class="arrow" @click="clickPage(1)" />
        </div>
        <div class="month_select_area">
          <ul class="month_list_area">
            <li
              v-for="(list, index) in year"
              class="month_list"
              @click="clickYear(list, index)"
              :key="list"
              :style="CheckMain(list, index)"
            >
              <span>{{ list }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "DateRangeYear",
  mixins: [],
  props: {
    minDate: {
      type: String,
      default: "2000"
    },
    maxDate: {
      type: String,
      default: "2020"
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "Дата"
    },
    value: {
      type: String,
      default: ""
    },
    keyAdd: {
      type: String,
      require: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      nowPage: 1,
      pageCount: null,
      year: [],
      subYear: [],
      activeYearFirst: "",
      activeYearSecond: "",
      activePageFirst: "",
      activePageSecond: "",
      showRangeYear: false,
      yearRangeValue: "",
      yearRangeMask: {
        mask: "от 9999 до 9999",
        placeholder: "",
        showMaskOnHover: false
      }
    };
  },
  mounted() {
    this.setValueYears();
  },
  methods: {
    checkValue() {
      this.showRangeYear = true;
    },
    showRangeWindow() {
      this.showRangeYear = false;
    },
    /**
     * Отвечает за связь модалки выбора месяцев и инпута.
     */
    setValueYearRange() {
      if (this.yearRangeValue.length === 15) {
        let firstMain = this.yearRangeValue.split(" ")[1];
        let secondMain = this.yearRangeValue.split(" ")[3];
        if (firstMain > secondMain) {
          let swap = secondMain;
          secondMain = firstMain;
          firstMain = swap;
        }
        /**
         * Проверка первой введенной даты на валидность
         */
        if (firstMain < this.minDate || firstMain >= this.maxDate) firstMain = this.minDate;
        if (secondMain > this.maxDate || secondMain <= this.minDate) secondMain = this.maxDate;
        this.yearRangeValue = `${firstMain} ${secondMain}`;
        this.value = `от ${firstMain} до ${secondMain}`;
        this.activeYearFirst = {
          name: parseInt(firstMain),
          index: this.findItem(firstMain, "index")
        };
        this.activeYearSecond = {
          name: parseInt(secondMain),
          index: this.findItem(secondMain, "index")
        };
        this.activePageFirst = this.findItem(firstMain, "page");
        this.activePageSecond = this.findItem(secondMain, "page");
      } else {
        this.activeYearFirst = "";
        this.activeYearSecond = "";
        this.activePageFirst = "";
        this.activePageSecond = "";
      }
    },
    /**
     * Отвечает за поиск индекса или страницы в зависимости от передаваемого статуса, передаем название item и статус.
     */
    findItem(item, status) {
      for (let i in this.subYear) {
        if (status === "index") {
          if (parseInt(this.subYear[i].indexOf(parseInt(item))) !== -1) {
            return parseInt(this.subYear[i].indexOf(parseInt(item)));
          }
        } else if (status === "page") {
          if (parseInt(this.subYear[i].indexOf(parseInt(item))) !== -1) {
            return parseInt(i) + 1;
          }
        }
      }
    },
    /**
     * Установка массива Годов в зависимости от передаваемых пропов.
     */
    setValueYears() {
      let count = this.maxDate - this.minDate;
      for (let i = 0; i <= count; i++) {
        this.year.push(parseInt(this.minDate) + i);
      }
      for (let i = 0; i < Math.ceil(this.year.length / 12); i++) {
        this.subYear[i] = this.year.slice(i * 12, i * 12 + 12);
      }
      this.year = this.subYear[this.nowPage - 1];
      this.pageCount = Math.ceil(count / 12);
    },
    /**
     * Отвечает за переключение Страниц.
     */
    clickPage(status) {
      if (status === 0) {
        if (this.nowPage > 1) {
          this.nowPage--;
        }
      }
      if (status === 1) {
        if (this.nowPage < this.pageCount) {
          this.nowPage++;
        }
      }
    },
    /**
     * Отвечает за нажатие на Года, отмечает 1 и 2 активные(главные) точки.
     */
    clickYear(list, index) {
      /**
       * Если отсутствует 1 активная точка
       */
      if (!this.activeYearFirst) {
        this.activeYearFirst = {
          name: list,
          index: index
        };
        this.activePageFirst = this.nowPage;
      } else if (!this.activeYearSecond) {
        /**
         * Если отсутствует 2 активная точка
         */
        this.activeYearSecond = {
          name: list,
          index: index
        };
        this.activePageSecond = this.nowPage;

        this.$emit("yearRange", {
          start: this.activeYearFirst.name,
          end: this.activeYearSecond.name
        });
        if (this.activeYearFirst.name > this.activeYearSecond.name) {
          this.yearRangeValue = `${this.activeYearSecond.name} ${this.activeYearFirst.name}`;
          this.value = `от ${this.activeYearSecond.name} до ${this.activeYearFirst.name}`;
        } else {
          this.yearRangeValue = `${this.activeYearFirst.name} ${this.activeYearSecond.name}`;
          this.value = `от ${this.activeYearFirst.name} до ${this.activeYearSecond.name}`;
        }
        this.showRangeYear = false;
      } else {
        /**
         * Если 2 активных точки есть, и мы кликаем заново
         */
        this.activeYearFirst = {
          name: list,
          index: index
        };
        this.activePageFirst = this.nowPage;

        this.activeYearSecond = "";
        this.activePageSecond = "";
      }
    },
    /**
     * Отвечает за отрисовку второстепенных годов, входящих в расстояние между главными точками.
     */
    checkBetween(index) {
      if (this.nowPage === this.activePageFirst || this.nowPage === this.activePageSecond) {
        /**
         * Это если нажать на одну и туже дату на разных страницах.
         */
        if (
          this.activeYearFirst &&
          this.activeYearSecond &&
          this.activeYearFirst.name === this.activeYearSecond.name
        ) {
          if (this.activePageFirst < this.activePageSecond) {
            if (
              (this.nowPage === this.activePageFirst && index > this.activeYearFirst.index) ||
              (this.nowPage === this.activePageSecond && index < this.activeYearSecond.index)
            ) {
              return "background: #F3F5FA; border-radius: 0";
            }
          } else if (this.activePageFirst > this.activePageSecond) {
            if (
              (this.nowPage === this.activePageFirst && index < this.activeYearFirst.index) ||
              (this.nowPage === this.activePageSecond && index > this.activeYearSecond.index)
            ) {
              return "background: #F3F5FA; border-radius: 0";
            }
          }
        }
        if (this.activeYearFirst && this.activeYearSecond) {
          /**
           * Это если две активные(main) точки находятся на одной станице.
           */
          if (this.activePageFirst === this.activePageSecond) {
            if (
              (index > this.activeYearFirst.index && index < this.activeYearSecond.index) ||
              (index < this.activeYearFirst.index && index > this.activeYearSecond.index)
            ) {
              return "background: #F3F5FA; border-radius: 0";
            }
          } else if (this.activePageFirst !== this.activePageSecond) {
            /**
             * Это если две активные(main) точки находятся на разных страницах.
             */
            /**
             * Если на странице первой активной точки
             */
            if (this.nowPage === this.activePageFirst) {
              if (
                this.activePageFirst < this.activePageSecond &&
                index > this.activeYearFirst.index
              ) {
                return "background: #F3F5FA; border-radius: 0";
              } else if (
                this.activePageFirst > this.activePageSecond &&
                index < this.activeYearFirst.index
              ) {
                return "background: #F3F5FA; border-radius: 0";
              }
            } else if (this.nowPage === this.activePageSecond) {
              /**
               * Если на странице второй активной точки
               */
              if (
                this.activePageFirst < this.activePageSecond &&
                index < this.activeYearSecond.index
              ) {
                return "background: #F3F5FA; border-radius: 0";
              } else if (
                this.activePageFirst > this.activePageSecond &&
                index > this.activeYearSecond.index
              ) {
                return "background: #F3F5FA; border-radius: 0";
              }
            }
          }
        }
        /**
         * Это для закрашивания страниц, которые входят в промежуток, если между активными страницами есть промежуточные.
         */
      } else if (this.betweenPages) {
        if (this.activeYearFirst && this.activeYearSecond) {
          return "background: #F3F5FA; border-radius: 0";
        }
      }
    },
    /**
     * Отвечает за отрисовку главных годов.
     */
    CheckMain(list, index) {
      /**
       * Если хотим выбрать одну дату (т.е 1 и 2 точки одинаковы)
       */
      if (
        this.activeYearFirst.name === list &&
        this.activeYearSecond.name === list &&
        this.nowPage === this.activePageFirst &&
        this.nowPage === this.activePageSecond
      ) {
        return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
      }
      /**
       * Если кликаем на одну и туже дату на разных страницах
       */
      if (
        this.activeYearFirst.name === list &&
        this.activeYearSecond.name === list &&
        this.activePageFirst !== this.activePageSecond
      ) {
        if (this.activePageFirst < this.activePageSecond) {
          if (this.nowPage === this.activePageFirst) {
            return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
          } else if (this.nowPage === this.activePageSecond) {
            return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
          }
        } else {
          if (this.nowPage === this.activePageFirst) {
            return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
          } else if (this.nowPage === this.activePageSecond) {
            return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
          }
        }
      }
      /**
       * Условия для двух разных точек на разных страницах.
       */
      if (this.activeYearFirst.name === list) {
        /**
         * Если находимся на странице 1 активной точки
         */
        if (this.nowPage === this.activePageFirst) {
          return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
        } else if (this.nowPage !== this.activePageFirst) {
          /**
           * Если находимся на странице на которой нет 1 активной точки
           */
          if (this.activeYearFirst && this.activeYearSecond) {
            /**
             * Условия которые определяют какие точки закрашивать, в зависимости от расположения 2х активных точек
             */
            if (this.ifSecondMore) {
              return "background: #F3F5FA; border-radius: 0";
            } else if (this.ifFirstMore) {
              return "background: #F3F5FA; border-radius: 0";
            }
          }
        }
      } else if (this.activeYearSecond.name === list) {
        /**
         * Если находимся на странице 2 активной точки
         */
        if (this.nowPage === this.activePageSecond) {
          return "color: #35455E; background-color: #FFFFFF; border-radius: 4px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);";
        } else if (this.nowPage !== this.activePageSecond) {
          /**
           * Если находимся на странице на которой нет 1 активной точки
           */
          if (this.activeYearFirst && this.activeYearSecond) {
            /**
             * Условия которые определяют какие точки закрашивать, в зависимости от расположения 2х активных точек
             */
            if (this.ifSecondMore) {
              return "background: #F3F5FA; border-radius: 0";
            } else if (this.ifFirstMore) {
              return "background: #F3F5FA; border-radius: 0";
            }
          }
        }
      } else {
        return this.checkBetween(index);
      }
    }
  },
  computed: {
    /**
     * Проверка на то, находимся ли мы на промежуточной странице.
     */
    betweenPages() {
      return (
        (this.nowPage > this.activePageFirst && this.nowPage < this.activePageSecond) ||
        (this.nowPage < this.activePageFirst && this.nowPage > this.activePageSecond)
      );
    },
    /**
     * Проверка на то, находимся ли мы на активных страницах.
     */
    checkMiddlePages() {
      return this.nowPage !== this.activePageFirst && this.nowPage !== this.activePageSecond;
    },
    ifFirstMore() {
      return (
        this.activePageFirst > this.activePageSecond &&
        (this.activeYearFirst.index > this.activeYearSecond.index || this.checkMiddlePages) &&
        this.nowPage <= this.activePageFirst &&
        this.nowPage >= this.activePageSecond
      );
    },
    ifSecondMore() {
      return (
        this.activePageFirst < this.activePageSecond &&
        (this.activeYearFirst.index < this.activeYearSecond.index || this.checkMiddlePages) &&
        this.nowPage >= this.activePageFirst &&
        this.nowPage <= this.activePageSecond
      );
    }
  },
  watch: {
    nowPage(val) {
      this.year = this.subYear[val - 1];
    },
    value() {
      /**
       * Если приходит какое-то значение, то мы его подставляем, и запускаем проверку и отрисовку рэнджа.
       * @type {String}
       */
      if (!this.value.start) {
        return;
      }
      let start = this.value.start[this.keyAdd]
        .split(" ")[0]
        .split("-")
        .reverse()
        .splice(1, 2)
        .join("-");
      let end = this.value.end[this.keyAdd]
        .split(" ")[0]
        .split("-")
        .reverse()
        .splice(1, 2)
        .join("-");
      this.yearRangeValue = `от ${start} до ${end}`;
      this.setValueYearRange();
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";
.year-picker-background {
  position: relative;
  .label-year {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $label-gray;
    margin-bottom: 6px;
  }
  input {
    background: $main-white;
    border: 1.5px solid $input-gray;
    border-radius: 3px;
    color: $main-gray;
    outline: none;
    padding: 7px 6px;
    height: 30px;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
  }
  input::placeholder {
    color: $button-gray;
    font-size: 14px;
    line-height: 16px;
  }
  input:focus {
    border: 1.5px solid $main-blue;
    box-shadow: 0px 1px 5px -2px rgba(18, 132, 255, 0.55);
  }
  input:disabled {
    background: $hover-gray;
    color: $inactive-gray;
    font-weight: 500;
  }

  input:invalid {
    box-shadow: none;
  }

  .invalid-input {
    border: 1px solid $red;
  }

  .invalid-label {
    color: $red;
  }

  #month-picker {
    .month_picker_container {
      position: absolute;
      z-index: 5;
      top: 110%;
      width: 268px;
      height: 170px;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      user-select: none;

      .year_select_area {
        display: flex;
        align-items: center;
        padding: 12px 24px;

        .now_year {
          margin: 0 12px;
        }

        .arrow {
          cursor: pointer;
        }

        .right_arrow {
          transform: rotate(180deg);
        }
      }

      .month_select_area {
        ul,
        li {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .month_list_area {
          height: 100%;
          display: flex;
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;

          .month_list {
            width: 60px;
            height: 25px;
            border-radius: 4px;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: transparent;
            text-align: center;
            font-size: 14px;
            color: #222;
          }

          .month_list:hover {
            background-color: #f6f6f6;
          }
        }
      }
    }
  }
}
</style>
